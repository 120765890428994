<template>
    <main>
        <UserHeader />
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Tabla de equivalencias</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Tabla de equivalencias</h2>
                
                <div class="table table--text pt-3">
                    <table class="table__content">                        
                        <HeadLevel :dataLevel="dataLevel" :withLabel="true" />
                        <tr v-for="(pointForExchange, key) in dataPointForExchange" :key="key">
                            <td><div><strong>{{ pointForExchange.name }}</strong></div></td>
                            <td v-for="(item, key2) in pointForExchange.items" :key="key2">{{ item.point }}</td>
                        
                        </tr>
                        
                    </table>
                </div>
            </div>
        </section>
    </main>
    
</template>

<script>
import { ref, computed } from "vue";
import router from "@/router"
import API from "@/api";
import HeadLevel from "@/components/HeadLevel";
import UserHeader from "@/views/private/user/components/UserHeader";

export default {
    name: 'TableEquivalence',
    components: {
        HeadLevel,
        UserHeader,
    },

    setup(props, context) {
        const dataLevel = ref([])
        const dataStateLevel = ref(null)
        const dataPointForExchange = ref([])
        const dataStatePointForExchange = ref(null)
        const baseURL = API.defaults.baseURL
        const baseURLStatic = API.defaults.baseURLStatic

        const getPointForExchange = () => {
            dataStatePointForExchange.value = 'loading'
            return API.get('/api/point-for-exchange')
            .then(response => {
                dataStatePointForExchange.value = 'success'
                dataPointForExchange.value = response.data
                
            })
        }          
        
        const getLevel = () => {
            dataStateLevel.value = 'loading'
            return API.get('/api/level')
            .then(response => {
                dataStateLevel.value = 'success'
                dataLevel.value = response.data
                
            })
        }
        return { router, dataPointForExchange, dataLevel, getPointForExchange, getLevel, baseURL, baseURLStatic }

    },
    created () {
        this.getLevel()
        this.getPointForExchange()
    }   
    
}
</script>